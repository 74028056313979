<template>
    <div>
        <div v-if="check.name" class="grid-parent-items-wrapper items-center gap-3 w-full pl-3 px-2">
            <font-awesome-icon icon="grip-vertical" class="text-base-content-300 text-gray-500" />
            <div class="grid-parent-items-inner justify-between transition-all duration-200 py-1 items-center gap-3 rounded-xl cursor-pointer">
                <div class="w-full bg-white rounded-md">
                    <div class="w-full">
                        <div class="flex w-full gap-3 items-center justify-between text-sm p-2">
                            <!-- Input for the label when edited -->
                            <div class="flex justify-between col-span-3">
                                <input v-if="edited" v-model="check.label" @click.stop="$emit('fieldNameClick', check)"
                                    @blur="onUpdateFieldLabel(check)"
                                    :class="!check.label ? 'border border-dashed border-red-300 ' : ''" type="text"
                                    class="section_name w-48 bg-transparent border-0 outline-none p-1 text-md text-base-content focus:bg-gray-200 rounded focus:border-none border-b border-dashed border-gray-300 truncate" />
                                <span v-else v-tippy :content="fieldLabel"
                                    class="section_name bg-transparent truncate w-48 border-0 outline-none p-1 text-md text-base-content font-semibold truncate">
                                    {{ fieldLabel }}
                                </span>
                                <!-- Edit button -->
                                <div v-if="this.$route.params.action !== 'view' && ISEDITPERMISSIONS"
                                    class="h-8 w-8 flex items-center justify-center rounded-full hover:bg-dvbrandcolor hover:text-white-text transition-all duration-150"
                                    :class="edited ? 'bg-dvbrandcolor text-white-text' : 'text-primary'" @click.stop="onEdit()">
                                    <font-awesome-icon icon="edit" class="cursor-pointer text-sm fill-current"
                                        v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }" />
                                </div>
                            </div>
                            <div class="col-span-3 flex justify-start">
                                <span v-tippy :content="getFieldName(check.name)">{{ getElementName }}</span>
                            </div>
                            
                            <!-- Entity information -->
                            <div class="flex ml-3 gap-1 col-span-4">
                                <p class="optionHeading">Entity: </p>
                                <span class ="optionHeading" v-for="entity in check.entity_types" :key="entity.id">{{ entity.name }}</span>
                            </div>
                            <!-- Expand/Collapse and Remove button -->
                            <div class="flex items-center justify-center gap-3 col-span-2">
                                <span class="text-white-text text-sm flex items-center gap-2 cursor-pointer" @click="onExpanded">
                                    <uparrow v-if="isExpanded" />
                                    <downarrow v-else />
                                </span>
                                <button class="cursor-pointer bg-gray-100 text-xs text-base-content-300 hover:bg-primary-focus hover:text-white-text w-6 h-6 flex items-center justify-center rounded-full text-center" 
                                    :class="{ formListItem__disabled : check.disabled || disabled }" @click="removeCheck(check)">
                                    <font-awesome-icon icon="times" class="fill-current text-current" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div :class="{'expanded': isExpanded, 'collapsed': !isExpanded}">
                        <div v-if="isExpanded" class="flex justify-between items-center text-sm gap-3 bg-white pl-2 pb-1">
                            <input v-if="editedDescription" v-model="check.description" @click.stop="$emit('fieldNameClick', check)"
                                    @blur="onUpdateFieldDescription(check)"
                                    :class="!check.description ? 'border border-dashed border-red-300 ' : ''" type="text"
                                    class="section_name w-full bg-transparent border-0 outline-none p-1 text-md text-base-content focus:bg-gray-200 rounded focus:border-none border-b border-dashed border-gray-300 truncate" />
                            <span v-else v-tippy :content="fieldDescription"
                                class="section_name bg-transparent truncate w-96 border-0 outline-none p-1 text-md text-base-content font-normal truncate">
                                Check Description : {{ fieldDescription }}
                            </span>
                            <!-- Edit button for description -->
                            <div v-if="this.$route.params.action !== 'view' && ISEDITPERMISSIONS"
                                class="h-8 w-8 flex items-center justify-center rounded-full hover:bg-dvbrandcolor hover:text-white-text transition-all duration-150"
                                :class="editedDescription ? 'bg-dvbrandcolor text-white-text' : 'text-primary'" @click.stop="onEditDescription()">
                                <font-awesome-icon icon="edit" class="cursor-pointer text-sm fill-current"
                                    v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }" />
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Expanded content section -->
                
            </div>
        </div>
    </div>
</template>

<script>
import uparrow from "@shared/assets/icon/svgs/uparrow.svg";
import downarrow from "@shared/assets/icon/svgs/downarrow.svg";

export default {
    name: "fields",
    props: {
        currentTab: {
            type: String
        },
        showSidePanel: {
            type: Boolean,
            default: false,
        },
        activeFieldPanel: {
            type: Object,
            default: () => { },
        },
        check: {
            type: Object,
            default: () => { },
        },
        options: {
            type: Object,
            default: () => { },
        },
        section: {
            type: Object,
            default: () => { },
        },
        allReportsRoles: {
            type: Array,
            default: () => [],
        },
        fieldClass: {
            type: String,
            default: '',
        },
        updateFieldAttributesMethod: {
            type: Function,
        },
        showDeleteIcon: {
            type: Boolean,
            default: true
        },
        draggable: {
            type: Boolean,
            default: true
        },
        ISEDITPERMISSIONS: {
            type: Boolean,
            default: true
        },
        inPackage: {
            type: Boolean,
        },
        isBlock: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: null,
        } 
    },
    components: {
        uparrow,
        downarrow,
    },
    data() {
        return {
            edited: false,
            rolesLoading: false,
            newValue: '',
            oldValue: '',
            isExpanded: false,
            newValueDesc: '',
            oldValueDesc: '',
            editedDescription: false,
        }
    },
    computed: {
        getElementName() {
            let text = ''
            const max_length = 25;
            if (this.check.name && this.check.name.length > max_length) {
                text = this.check.name.substring(0, max_length) + ' ...'
                return text
            } else {
                return this.check.name
            }

        },
        fieldLabel() {
            return this.getFieldLabel(this.check);
        },
        fieldDescription()
        {
            return this.getFieldDescription(this.check);
        },
        useblockvisible: {
            get() {
                return this.check.block_visibility
            },
            set(val) {
                this.check.block_visibility = val;
            },
        }, 
        canDeleteField() {
            return !this.check.block_id
        }
    },
    mounted() {
        this.oldValue = this.check.label;
        this.oldValueDesc =  this.check.description;
    },
    methods: {
        getFieldLabel(field) {
            return field.label;
        },
        getFieldName(field) {
            return field.name;
        },
        removeCheck(check) {
            this.$emit('removeCheck', check);
        },
        getFieldDescription(field) {
            return field.description;
        },
        async onUpdateFieldLabel(check) {
            this.edited = false;
            if (!check.label) {
                this.$toast.error("label cannot be empty");
                return;
            }
            else{
                this.check.label = this.oldValue;
                check.label = this.newValue;
                this.$emit('updateFieldLabel', { check })
            }
        },
        onUpdateFieldDescription(check){
            this.editedDescription = false;
            this.check.description = this.oldValueDesc;
            check.description = this.newValueDesc;
            this.$emit('updateFieldLabel', { check })
        },
        onExpanded()
        {
            this.isExpanded = !this.isExpanded
        },
        onEdit() {
            if (this.$route.params.action == 'view') {
                return
            }
            if (!this.check.label) {
                this.$toast.error("Label cannot be empty");
                return;
            }
            this.edited = !this.edited;
        },
        onEditDescription(){
            if (this.$route.params.action == 'view') {
                return
            }
            this.editedDescription = !this.editedDescription;
        }
    },
    watch: {
        'check.label': function (newVal, oldVal) {
            this.newValue = newVal;
            this.oldValue =  oldVal
            console.log(oldVal, newVal);
        },
        'check.description': function (newVal, oldVal) {
            this.newValueDesc = newVal;
            this.oldValueDesc = oldVal;
            console.log(oldVal);
        },
    },
};
</script>

<style lang="scss" scoped>

.block_visible {
    position: relative;
    align-self: center;
    padding-left: 10px;
}
.packageFieldRole{
    margin-left:1.8%;
}
.optionHeading{
    color: #0236bb;
    font-weight: 400;
}
.optionCircle{
    margin-top:5px;
}

.field-tags_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.block-role-panel-pad {
    padding-left: 18px;
}

.collect-data {
    max-width: 220px;
    min-width: 810px;
    width: 220px;  
    @apply bg-primary-100 border-primary-100;
    @apply h-7;
}

.grid-parent-items-wrapper {
  display: grid;
  grid-template-columns: 16px 1fr;
}

.grid-parent-items-inner {
  display: grid;
  grid-template-columns: 1fr;
}

.grid-child-items-wrapper {
  display: grid;
  grid-template-columns: 100px 1fr 50px;
}
.item {
  cursor: move;
  padding: 10px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

/* Optional: You can add styling for the ghost class (the element while being dragged) */
.ghost {
  opacity: 0.5;
}
</style>
